import React, { useState, useEffect } from "react";
import { Container, Col, Row, Image } from "react-bootstrap";
import SEO from "../../components/shared/seo";
import { FadeUpBox, FadeIn } from "../../components/shared/transitions";
import {
  OverlayFill,
  OverlayFillGradient,
} from "../../components/shared/overlay";
import { useStaticQuery, graphql } from "gatsby";
import { CaseStudyNav } from "../../components/shared/case-study-nav";
import { Controller, Scene } from "react-scrollmagic";
import LayoutCaseStudy from "../../components/layouts/layout-case-study";
import { ParallaxBanner } from "react-scroll-parallax";
import { QuoteBlock } from "../../components/shared/quote-block";
import { Helmet } from "react-helmet";
import Swiper from "react-id-swiper";
import technicalIcon from "../../images/technical-icon.svg";
import uxIcon from "../../images/ux-icon.svg";
import kenticoLogo from "../../images/kentico-logo.png";
import sitefinityLogo from "../../images/sitefinity_logo.png";
import wordpressLogo from "../../images/wordpress-logo.png";
import solution01Icon from "../../images/solution01-icon.png";
import solution02Icon from "../../images/solution02-icon.png";
import solution03Icon from "../../images/solution03-icon.png";
import firstClickIcon from "../../images/first-click-icon.svg";
import onTimeIcon from "../../images/on-time-icon.svg";
import timeOnTaskIcon from "../../images/time-on-task-icon.svg";
import retentionIcon from "../../images/retention-icon.svg";
import engagementIcon from "../../images/engagement-icon.svg";
import heatMapIcon from "../../images/heat-map-icon.svg";
import adoptionIcon from "../../images/adoption-icon.svg";
import clickEventIcon from "../../images/click-events-icon.svg";
import successIcon from "../../images/success-icon.svg";
import investTradeVideo from "../../video/invest-and-trade-home.mp4";
import investTradePoster from "../../images/invest-trade-poster.png";




function tabOne() {
  var element = document.getElementById("accountability-tabs");
  element.classList.remove("tab-two-active");
  element.classList.add("tab-one-active");

}

function tabTwo() {
  var element = document.getElementById("accountability-tabs");
  element.classList.remove("tab-one-active");
  element.classList.add("tab-two-active");

}



function videoOne() {
  var element = document.getElementById("video-tabs");
  element.classList.remove("tab-one-active", "tab-two-active", "tab-three-active", "tab-four-active", "tab-five-active");
  element.classList.add("tab-one-active");

}


function videoTwo() {
  var element = document.getElementById("video-tabs");
  element.classList.remove("tab-one-active", "tab-two-active", "tab-three-active", "tab-four-active", "tab-five-active");
  element.classList.add("tab-two-active");

}


function videoThree() {
  var element = document.getElementById("video-tabs");
  element.classList.remove("tab-one-active", "tab-two-active", "tab-three-active", "tab-four-active", "tab-five-active");
  element.classList.add("tab-three-active");

}


function videoFour() {
  var element = document.getElementById("video-tabs");
  element.classList.remove("tab-one-active", "tab-two-active", "tab-three-active", "tab-four-active", "tab-five-active");
  element.classList.add("tab-four-active");

}

function videoFive() {
  var element = document.getElementById("video-tabs");
  element.classList.remove("tab-one-active", "tab-two-active", "tab-three-active", "tab-four-active", "tab-five-active");
  element.classList.add("tab-five-active");

}


function technicalScope() {
  var element = document.getElementById("technicalProcess");
  element.classList.remove("technical-slider-active", "uxprocess-slider-active");
  element.classList.add("technical-slider-active");

}



function uxProcess() {
  var element = document.getElementById("technicalProcess");
  element.classList.remove("technical-slider-active", "uxprocess-slider-active");
  element.classList.add("uxprocess-slider-active");

}


function technicalChallenge() {
  var element = document.getElementById("challenge");
  element.classList.remove("technical-challenge-slider-active", "ux-challenge-slider-active");
  element.classList.add("technical-challenge-slider-active");

}


function uxChallenge() {
  var element = document.getElementById("challenge");
  element.classList.remove("technical-challenge-slider-active", "ux-challenge-slider-active");
  element.classList.add("ux-challenge-slider-active");

}






const params1 = {
  slidesPerView: "auto",
  spaceBetween: 15,
  freeMode: true,
  freeModeMomentumBounce: false,
  freeModeMomentum: false,
  freeModeMinimumVelocity: 0.2,
  longSwipesRatio: 0.8,
  loop: true,
  speed: 500,
  slidesOffsetAfter: 525,
  freeModeSticky: true,
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
};

function InvestTradePage({ location }) {
  // const { state = {} } = location;
  const [animate, setAnimate] = useState(false);
  const investAndTradeimages = useStaticQuery(graphql`
    query {
      nextWorkImage: file(relativePath: { eq: "stjohn-page-banner.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      investTradeLogoSm: file(relativePath: { eq: "invest-trade-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);

  useEffect(() => {
    const timer = setTimeout(() => {
      setAnimate(true);
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  });

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {`
              {
                "@context": "http://schema.org",
                "@type": "Organization",
                "name": "Diviv Group",
                "description": ".NET applications, Progress Sitefinity CMS, Drupal, GovCMS, Sitecore Experience Platform, Microsoft Sharepoint, Dynamics CRM and Azure Hosting support in Perth Australia. Coupled with our creative and user experience team we are able to provide expert consultation and resources on a wide range of digital development projects across many industries.",
                "url": "https://www.diviv.com.au",
                "image": "https://www.diviv.com.au/icons/icon-96x96.png",
                "address": {
                  "@type": "PostalAddress",
                  "streetAddress": "165 / 15 Aberdeen St",
                  "addressLocality": "Perth",
                  "addressRegion": "WA",
                  "postalCode": "6000",
                  "addressCountry": "AU"
                },
                "aggregateRating": {
                  "@type": "AggregateRating",
                  "ratingValue": "5",
                  "ratingCount": "1"
                },
                "review": {
                  "@type": "Review",
                  "author": "Michael Johnson",
                  "datePublished": "2020-03-09",
                  "reviewBody": "Diviv is a solution based partner that delivers front and back end website solutions with innovation, professionalism and integrity. They are a great team and a pleasure to work with.",
                  "reviewRating": {
                    "@type": "Rating",
                    "bestRating": "5",
                    "ratingValue": "5",
                    "worstRating": "1"
                  }
                }
              }
              `}
        </script>
      </Helmet>
      <SEO
        title="Invest and Trade website"
        keywords="Diviv our work case studies"
        description="Diviv has extensive experience resolving complex digital problems for businesses in a wide variety of industries. Refer to our case studies to understand how we have delivered successful business outcomes for businesses like yours."
      />
      <LayoutCaseStudy>
        <div
          id="investtrade-content"
          className="page-layout"
          data-menu-className="inverted"
        >
          <Container>
            <Row>
              <Col sm={12} md={12} lg={8}>
                <div className="client-logo--lg mb-4">
                  <Image src={investAndTradeimages.investTradeLogoSm.childImageSharp.fluid.src} />
                </div>
                <FadeUpBox pose={animate ? "enter" : "exit"}>
                  <FadeIn>
                    <h6>Sitefinity CMS Build</h6>
                  </FadeIn>
                  <FadeIn duration={200}>
                    <h2>Invest and Trade</h2>
                  </FadeIn>
                  <FadeIn duration={200}>
                    <p>
                      Invest and Trade Western Australia provides a ‘front door’ for investors and customers
                      interested in doing business with Western Australia. A key element of the WA Government’s economic plan.
                    </p>
                    <p>
                      Invest and Trade WA, will deliver a collaborative, responsive and client-focussed website
                      service that makes connections between businesses and investors, expanding and diversifying the economy.
                    </p>
                  </FadeIn>
                </FadeUpBox>
              </Col>

              <Col sm={12} md={12} lg={4}>
                <FadeUpBox pose={animate ? "enter" : "exit"}>
                  <FadeIn>
                    <div className="client-meta">
                      Discover all of what Invest and trade has to offer by visiting their
                      website:
                      <div className="client-meta__link">
                        <a href="https://investandtrade.wa.gov.au/" target="_blank">
                          investandtrade.wa.gov.au
                        </a>
                      </div>
                    </div>
                  </FadeIn>
                </FadeUpBox>
              </Col>
            </Row>
          </Container>

          <Controller>
            <Scene
              triggerElement="carousel01"
              reverse={false}
              duration={2000}
              offset={200}
            >
              {(progress, event) => (
                <section id="carousel01">
                  <Container>
                    <Row id="challenge" className="carousel-section technical-challenge-slider-active">
                      <Col lg={6} sm={12} className="col-left" >
                        <FadeUpBox pose={progress > 0 ? "enter" : "exit"}>
                          <FadeIn>
                            <h2>DEFINE THE CHALLENGE</h2>
                            <p>
                              Using a highly collaborative approach with key stakeholders, we set out to clearly identify
                              requirements and achievable goals. By keeping our clients informed regularly throughout the
                              development process, we’re able to stay on track delivering on-time and on budget.
                            </p>
                          </FadeIn>
                        </FadeUpBox>
                      </Col>

                      <Col lg={6} sm={12} className="col-right">
                        <FadeUpBox pose={progress > 0 ? "enter" : "exit"}>
                          <FadeIn>
                            <div className="carousel-slider-container">
                              <div className="carousel__outer swiper1">
                                <Swiper {...params1}>
                                  <div className="swiper-slide">
                                    <div className="slide__icon">
                                      <img
                                        src={technicalIcon}
                                        alt="Technical icon"
                                      />
                                    </div>
                                    <div className="slide__number">01</div>
                                    <div className="slide__title">
                                      Challenge
                                    </div>

                                    <div className="slide__sub-title">
                                      Replicate Existing Design
                                    </div>
                                    <p>
                                      By taking an accumulated set of static designs, our development team can start the coding
                                      process and replicate the design ensuring what has been proposed will be implemented.
                                    </p>
                                  </div>

                                  <div className="swiper-slide">
                                    <div className="slide__icon">
                                      <img
                                        src={technicalIcon}
                                        alt="Technical icon"
                                      />
                                    </div>
                                    <div className="slide__number">02</div>
                                    <div className="slide__title">
                                      Challenge
                                    </div>
                                    <div className="slide__sub-title">
                                      Easy Content Editing
                                    </div>
                                    <p>
                                      By building a set of reusable components, content editors will be able to easily create new pages
                                      and expand the website as it grows whilst staying own brand.
                                    </p>
                                  </div>
                                  <div className="swiper-slide">
                                    <div className="slide__icon">
                                      <img
                                        src={technicalIcon}
                                        alt="Technical icon"
                                      />
                                    </div>
                                    <div className="slide__number">03</div>
                                    <div className="slide__title">
                                      Challenge
                                    </div>
                                    <div className="slide__sub-title">
                                      Mobile First
                                    </div>
                                    <p>
                                      By adopting a mobile first strategy we're able to provide wonderful user experiences no matter
                                      what device is used. With the percentage of mobile users on the rise, it's a necessity to access
                                      Invest and Trade information whilst on the go.
                                    </p>
                                  </div>
                                  <div className="swiper-slide">
                                    <div className="slide__icon">
                                      <img
                                        src={technicalIcon}
                                        alt="Technical icon"
                                      />
                                    </div>
                                    <div className="slide__number">04</div>
                                    <div className="slide__title">
                                      Challenge
                                    </div>
                                    <div className="slide__sub-title">
                                      Reducing Costs
                                    </div>
                                    <p>
                                      By using as many 'out of the box' features Sitefinity provides, we're able to drastically cut down
                                      development time making it much cheaper than building bespoke components.
                                    </p>
                                  </div>
                                </Swiper>
                                <div className="swiper-pagination swiper-pagination1"></div>
                              </div>
                            </div>
                          </FadeIn>
                        </FadeUpBox>
                      </Col>
                    </Row>
                  </Container>
                </section>
              )}
            </Scene>

            <Scene
              triggerElement="#discovery"
              reverse={false}
              duration={2000}
              offset={200}
            >
              {(progress, event) => (
                <section id="discovery">
                  <Container>
                    <Row>
                      <Col lg={5} sm={12} className="cms-logos">
                        <FadeUpBox pose={progress > 0 ? "enter" : "exit"}>
                          <FadeIn>
                            <img src={sitefinityLogo} alt="Sitefinity Logo" />
                          </FadeIn>
                        </FadeUpBox>
                      </Col>

                      <Col lg={7} sm={12} className="desc">
                        <div>
                          <FadeUpBox pose={progress > 0 ? "enter" : "exit"}>
                            <FadeIn>
                              <h2>Progress Sitefinity</h2>
                              <p>
                                Diviv Group have been working with Progress Sitefinity CMS for many years and
                                are well versed in the available feature sets and functionality available in the system.
                                We pride ourselves in being able to deliver solutions to complex problems quickly
                                without the baggage of our competitors. We find that new features can generally be
                                developed and delivered within days, not months.
                              </p>
                            </FadeIn>
                          </FadeUpBox>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </section>
              )}
            </Scene>
          </Controller>

          <Controller>
            <Scene
              triggerElement="#solution"
              reverse={false}
              duration={2000}
              offset={200}
            >
              {(progress, event) => (
                <section id="solution" style={{ background: "#128996" }}>
                  <Container>
                    <Row>
                      <Col lg={4} sm={12} className="title">
                        <FadeUpBox pose={progress > 0 ? "enter" : "exit"}>
                          <FadeIn>
                            <h2>How we solve your problem</h2>
                          </FadeIn>
                        </FadeUpBox>
                      </Col>

                      <Col lg={8} sm={12} className="desc">

                        <FadeUpBox pose={progress > 0 ? "enter" : "exit"}>
                          <FadeIn>
                            <p>
                              The Invest &amp; Trade website was built using the CMS product Sitefinity. In order to speed up the development,
                              JTSI and Diviv Group agreed to utilize as many OOB ("Out of the Box") components as possible.
                            </p>
                            <Row>
                              <Col md={6} sm={12}>
                                <ul className="solution-list mb-0">
                                  <li>Navigation has clickable links to subpages and it then links to content located on the wa.gov JTSI website</li>
                                  <li>We built three templates, including the homepage</li>
                                  <li>Built 9 Sector page templates</li>
                                  <li>The website is fully mobile responsive for android and IOS devices</li>
                                </ul>
                              </Col>
                              <Col md={6} sm={12}>
                                <ul className="solution-list mb-0">
                                  <li>The news/events functionality has been linked to the appropriate modules in the CMS (Sitefinity)</li>
                                  <li>Vimeo/YouTube videos has been set up as a native "Embed Widget"</li>
                                  <li>Out of the box workflow has been enabled for a two-level approval process for publishing the content</li>
                                </ul>
                              </Col>
                            </Row>

                          </FadeIn>
                        </FadeUpBox>

                      </Col>
                    </Row>
                  </Container>


                </section>
              )}
            </Scene>
          </Controller>

          <Controller>
            <Scene
              triggerElement="#solution-video"
              reverse={false}
              duration={2000}
              offset={200}
            >
              {(progress, event) => (
                <section id="solution-video" style={{ paddingTop: 100 }}>
                  <Container>
                    <Row>
                      <Col lg={12} sm={12}>
                        <FadeUpBox pose={progress > 0 ? "enter" : "exit"}>
                          <FadeIn>
                            <h2>SHOWCASE</h2>
                            <div>
                              <video playsInline controls muted width="1078" height="810" poster={investTradePoster}>
                                <source src={investTradeVideo} type="video/mp4" />
                              </video>
                            </div>
                          </FadeIn>
                        </FadeUpBox>
                      </Col>
                    </Row>
                  </Container>
                </section>
              )}
            </Scene>
          </Controller>

        </div>

        {/*  <Scene
                    triggerElement="#bottom-section"
                    reverse={false}
                    duration={2000}
                    offset={200}
                >
                    {(progress, event) => (
                        <section className="quote-wrapper">
                            <Container>
                                <QuoteBlock
                                    author="Michael Johnson"
                                    authorPosition="Senior Business Analyst"
                                    quoteText="Diviv is a solution based partner that delivers
                              front and back end website solutions with
                              innovation, professionalism and integrity. They
                              are a great team and a pleasure to work with."
                                    clientLogoUrl={eaImages.eaLogoSm.childImageSharp.fluid.src}
                                />
                            </Container>
                        </section>
                    )}
                </Scene> */}

        <CaseStudyNav
          nextUrl="/our-work/stjohn-ambulance/beats/"
          nextImage={investAndTradeimages.nextWorkImage.childImageSharp.fluid.src}
          nextTitle="BEATS St John Ambulance"
        />
      </LayoutCaseStudy>
    </>
  );
}

export default InvestTradePage;
