import React from "react";
import { Footer } from "../global/footer";

interface Props {}

const LayoutCaseStudy: React.FC<Props> = props => {
  return (
    <div className="layout--case-study">
      {props.children}
      <Footer />
    </div>
  );
};

export default LayoutCaseStudy;
