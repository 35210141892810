import React from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import { PrimaryButton } from "../buttons/primary-button";

interface ICaseStudyNavProps {
  prevUrl: string;
  prevImage: string;
  prevTitle: string;
  nextUrl: string;
  nextImage: string;
  nextTitle: string;
}

export const CaseStudyNav: React.FC<ICaseStudyNavProps> = (
  props: ICaseStudyNavProps
) => {
  const {
    nextUrl,
    prevUrl,
    prevImage,
    prevTitle,
    nextImage,
    nextTitle,
  } = props;

  return (
    <div className="case-study-nav">
      {prevUrl && (
        <Link to={prevUrl} className="case-study-nav__item">
          <div
            className="case-study-nav__item__bg-image background-image"
            style={{
              backgroundImage: `url("${prevImage}")`,
            }}
          ></div>
          <div className="case-study-nav__item__overlay"></div>
          <Container>
            <Row>
              <Col>
                <div className="text-block">
                  <p>Previous Project</p>
                  <h2>{prevTitle}</h2>
                </div>
              </Col>
            </Row>
          </Container>
        </Link>
      )}
      <Link to={nextUrl} className="case-study-nav__item">
        <div
          className="case-study-nav__item__bg-image background-image"
          style={{
            backgroundImage: `url("${nextImage}")`,
          }}
        ></div>
        <div className="case-study-nav__item__overlay"></div>
        <Container>
          <Row>
            <Col>
              <div className="text-block mr-auto ml-0">
                <p>Next Project</p>
                <h2>{nextTitle}</h2>
                <Link to={nextUrl}>View case study</Link>
              </div>
            </Col>
          </Row>
        </Container>
      </Link>
    </div>
  );
};

CaseStudyNav.defaultProps = {
  prevImage:
    "https://i2.wp.com/kalkinemedia.com/wp-content/uploads/2018/10/Wesfarmers-Limited.jpg?fit=1000%2C666&ssl=1",
  nextImage: "https://scx1.b-cdn.net/csz/news/800/2019/namedfedorth.jpg",
};
